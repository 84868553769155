@import 'import';
.Icon {
  .injected-svg {
    transition: transform 300ms;
  }
}

.IconBase {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: rem(24);
  height: rem(24);

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .injected-svg {
    display: block;
    width: 100%;
    // Please, dont change sizes here!
    // This is a basic component, and many other components depend on it. Each of which requires different sizes!
    // If you need to set the size, please set it for a specific icon component (as a whole) where it is used!
    height: 100%;
  }
}
